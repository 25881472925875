<template>
  <div class="profile-settings">
    <div class="mem-title settings-header" v-if="!showFormTitle">
      {{ $t("settings") }}
    </div>
    <div class="mobile-header" v-if="showFormTitle">
      <div class="button-back" @click="openMobileMenu">
        <i class="material-icons">keyboard_arrow_left</i>
        <span>{{ $t("back") }}</span>
      </div>
      <div class="mem-title section-title">
        <span v-if="mobileView.profile">{{ $t("profile") }}</span>
        <span v-if="mobileView.addresses">{{ $t("address") }}</span>
        <span v-if="mobileView.account">{{ $t("account") }}</span>
        <span v-if="mobileView.subscription">Spartan EDGE</span>
        <span v-if="mobileView.connections">{{ $t("connections") }}</span>
      </div>
    </div>

    <transition :name="transitionName" mode="out-in">
      <!-- mobile menu -->

      <div class="mobile-menu" v-if="mobileView.showMobileMenu" key="1">
        <div class="menu-item" @click="openMobileSection('profile')">
          <span>{{ $t("profile") }}</span>
          <i class="material-icons-round">keyboard_arrow_right</i>
        </div>
        <div class="menu-item" @click="openMobileSection('addresses')">
          <span>{{ $t("address") }}</span>
          <i class="material-icons-round">keyboard_arrow_right</i>
        </div>
        <div
          class="menu-item"
          @click="openMobileSection('subscription')"
          v-if="userData && userData.spartan_country_code === 'US'"
        >
          <span>Spartan EDGE</span>
          <i class="material-icons-round">keyboard_arrow_right</i>
        </div>
        <div class="menu-item" @click="openMobileSection('account')">
          <span>{{ $t("account") }}</span>
          <i class="material-icons-round">keyboard_arrow_right</i>
        </div>
        <div class="menu-item" @click="openMobileSection('connections')">
          <span>{{ $t("connections") }}</span>
          <i class="material-icons-round">keyboard_arrow_right</i>
        </div>
      </div>

      <div class="settings-forms" v-else key="2">
        <!-- profile form -->
        <v-form
          class="settings-card"
          ref="profileForm"
          v-if="mobileView.profile && isLoadedStatus.userData"
          :key="profileFormKey"
        >
          <div class="card-header">
            <span>{{ $t("profile") }}</span>
          </div>
          <div class="card-body">
            <v-text-field
              class="mem-required-input"
              v-on:input="changeFn"
              :rules="[() => !!userData.first_name || $t('field_required')]"
              :label="$t('first_name')"
              v-model.trim="userData.first_name"
              :error="!userData.first_name"
              required
              :append-icon="!!userData.first_name ? '' : 'error'"
              data-testid="first-name"
            ></v-text-field>
            <v-text-field
              class="mem-required-input"
              v-on:input="changeFn"
              :rules="[() => !!userData.last_name || $t('field_required')]"
              :label="$t('last_name')"
              v-model.trim="userData.last_name"
              :error="!userData.last_name"
              required
              :append-icon="!!userData.last_name ? '' : 'error'"
              data-testid="last-name"
            ></v-text-field>
            <v-select
              v-on:input="changeFn"
              :items="genders"
              item-text="full"
              item-value="short"
              v-model="userData.gender"
              :label="$t('gender')"
              append-icon="keyboard_arrow_down"
              data-testid="gender"
            ></v-select>
            <v-menu
              v-if="!useDevicePicker"
              v-model="dateSelector"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="formattedDate"
                  :rules="[() => !!userData.birth_date || $t('field_required')]"
                  readonly
                  :label="$t('birth_date')"
                  v-on="on"
                  append-icon="keyboard_arrow_down"
                  v-on:click:append="dateSelector = true"
                  data-testid="birth-date"
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeFn"
                v-model="userData.birth_date"
                @input="dateSelector = false"
                :locale="userLanguage"
              ></v-date-picker>
            </v-menu>
            <!-- mobile input -->
            <v-text-field
              v-if="useDevicePicker"
              v-on:input="changeFn"
              v-model="userData.birth_date"
              type="date"
              :rules="[() => !!userData.birth_date || $t('field_required')]"
              :label="$t('birth_date')"
              append-icon="keyboard_arrow_down"
              class="mobile-picker"
              data-testid="birth-date"
            ></v-text-field>
            <!-- phone -->
            <mem-phone-field
              class="settings-field"
              :label="$t('phone_number')"
              v-model="userData.mobile_phone"
              :phone-data="userData.mobile_phone_parsed"
              :phone-country="userData.spartan_country_code"
              data-testid="phone-number"
            ></mem-phone-field>
            <v-select
              v-on:input="changeFn"
              :items="T_SHIRT_SIZES"
              v-model="userData.tshirt_size"
              :label="$t('t_shirt_size')"
              append-icon="keyboard_arrow_down"
              data-testid="t-shirt-size"
            ></v-select>
            <v-text-field
              v-on:input="changeFn"
              v-model.trim="userData.emergency_contact_name"
              :label="$t('emergency_contact_name')"
              data-testid="emergency-contact-name"
            ></v-text-field>
            <!-- emergency phone -->
            <mem-phone-field
              class="settings-field"
              :label="$t('emergency_contact_phone')"
              v-model="userData.emergency_contact_phone"
              :phone-data="userData.emergency_contact_phone_parsed"
              :phone-country.sync="userData.spartan_country_code"
              data-testid="emergency-contact-phone"
            ></mem-phone-field>
            <v-select
              v-on:input="changeFn"
              :items="nationalities"
              v-model="userData.nationality"
              :label="$t('nationality')"
              append-icon="keyboard_arrow_down"
              data-testid="nationality"
            ></v-select>
            <div class="card-buttons" v-if="formButtons.showProfile">
              <mem-button :btnType="'tertiary-dark'" @click="cancelProfile">{{
                $t("cancel")
              }}</mem-button>
              <mem-button :btnType="'secondary-dark'" @click="saveUser">{{
                $t("save")
              }}</mem-button>
            </div>
          </div>
        </v-form>

        <!-- address form -->
        <v-form
          class="settings-card"
          ref="addressForm"
          v-if="mobileView.addresses"
        >
          <div class="card-header">
            <span>{{ $t("address") }}</span>
          </div>
          <div class="card-body">
            <mem-address-field
              :label="$t('street_address')"
              v-model.trim="userData.address1"
              data-testid="street-address"
              @input="changeAddressFn"
              @on-place-change="fillAddressForm"
            />
            <!-- -- -->
            <!-- <v-text-field
              name="g_autocomplete"
              id="autocomplete"
              v-model.trim="userData.address1"
              v-on:input="changeAddressFn"
              :label="$t('street_address')"
              data-testid="street-address"
            ></v-text-field> -->
            <v-text-field
              v-model.trim="userData.address2"
              v-on:input="changeAddressFn"
              placeholder
              :label="$t('address_line_2')"
              data-testid="address-line-2"
            ></v-text-field>
            <v-select
              v-model="userData.country_code"
              v-on:input="changeAddressFn"
              :items="countries"
              item-text="value"
              item-value="id"
              :label="$t('country')"
              append-icon="keyboard_arrow_down"
              data-testid="country"
            ></v-select>
            <v-select
              v-if="statesList"
              v-model="userData.state"
              v-on:input="changeAddressFn"
              :items="statesList"
              item-text="value"
              item-value="id"
              :label="$t('state')"
              append-icon="keyboard_arrow_down"
              data-testid="state"
            ></v-select>
            <v-text-field
              v-model.trim="userData.city"
              v-on:input="changeAddressFn"
              :label="$t('city')"
              data-testid="city"
            ></v-text-field>
            <v-text-field
              v-model.trim="userData.zip"
              v-on:input="changeAddressFn"
              :label="$t('zip')"
              data-testid="zip"
            ></v-text-field>
            <div class="card-buttons" v-if="formButtons.showAddress">
              <mem-button :btnType="'tertiary-dark'" @click="cancelAddress">{{
                $t("cancel")
              }}</mem-button>
              <mem-button :btnType="'secondary-dark'" @click="saveAddress">{{
                $t("save")
              }}</mem-button>
            </div>
          </div>
        </v-form>

        <!-- subscription -->
        <template v-if="isSubscriptionAvailable">
          <!-- subscription notification-->
          <div
            class="subscription-section"
            v-if="mobileView.subscription && !hasSubscriptionStatus"
          >
            <spartan-plus />
          </div>
          <!-- subscription form -->
          <v-form
            class="settings-card"
            v-if="mobileView.subscription && hasSubscriptionStatus"
          >
            <div class="card-header">Spartan EDGE</div>
            <div class="card-body">
              <user-subscriptions :subscription="userData.subscription" />
            </div>
          </v-form>
        </template>

        <!-- account form -->
        <v-form
          class="settings-card"
          ref="accountForm"
          v-if="mobileView.account"
          v-model="accountForm"
        >
          <div class="card-header">
            <span>{{ $t("account") }}</span>
          </div>
          <div class="card-body">
            <v-text-field
              v-model.trim="userData.email"
              class="mem-required-input"
              :label="$t('email')"
              v-on:input="changeAccountFn"
              :rules="[
                () => !!userData.email || $t('field_required'),
                (val) => rules.email(val) || $t('invalid_email'),
                rules.emailExist,
              ]"
              :hint="pendingEmail"
              persistent-hint
              :append-icon="accountForm ? '' : 'error'"
              data-testid="email"
            ></v-text-field>
            <div style="height: 12px" v-if="pendingEmail"></div>

            <v-text-field
              class="password-input"
              :label="$t('password')"
              :value="password"
              type="password"
              readonly
              data-testid="password"
            >
              <template v-slot:append>
                <span
                  v-if="userData.empty_password"
                  class="input-control"
                  @click="showPasswordDialog('create')"
                >
                  {{ $t("create") }}
                </span>
                <span
                  v-else
                  class="input-control"
                  @click="showPasswordDialog('change')"
                >
                  {{ $t("edit") }}
                </span>
              </template>
            </v-text-field>

            <div class="card-buttons" v-if="formButtons.showAccount">
              <mem-button :btnType="'tertiary-dark'" @click="cancelAccount">{{
                $t("cancel")
              }}</mem-button>
              <mem-button :btnType="'secondary-dark'" @click="changeEmail">{{
                $t("save")
              }}</mem-button>
            </div>
          </div>
        </v-form>

        <!-- connections form-->
        <div
          v-if="mobileView.connections"
          class="settings-card connections-block"
        >
          <div class="card-header">{{ $t("connections") }}</div>

          <div class="card-body">
            <!-- apple -->
            <div class="connection-item">
              <template v-if="socialConnections.apple">
                <div class="connection-details apple-connection">
                  <img
                    class="connection-logo"
                    src="@/assets/apple_icon_light.svg"
                  />
                  <div class="connection-info">
                    <span class="connection-title">{{
                      `${socialConnections.apple.first_name} ${socialConnections.apple.last_name}`
                    }}</span>
                    <span class="connection-subline">{{
                      socialConnections.apple.email
                    }}</span>
                  </div>
                </div>
                <i
                  class="material-icons connection-control"
                  @click="removeConnection(socialConnections.apple)"
                  >remove_circle</i
                >
              </template>
              <!-- <apple-auth-button
                v-else
                use-token
                class="auth-button"
                :redirect-url="connectionRedirectUrl"
                :text="$t('connect_with_apple')"
              /> -->
            </div>
            <!-- google -->
            <div class="connection-item">
              <template v-if="socialConnections.google_oauth2">
                <div class="connection-details google-connection">
                  <img class="connection-logo" src="@/assets/G_icon.svg" />
                  <div class="connection-info">
                    <span class="connection-title">{{
                      `${socialConnections.google_oauth2.first_name} ${socialConnections.google_oauth2.last_name}`
                    }}</span>
                    <span class="connection-subline">{{
                      socialConnections.google_oauth2.email
                    }}</span>
                  </div>
                </div>
                <i
                  class="material-icons connection-control"
                  @click="removeConnection(socialConnections.google_oauth2)"
                  >remove_circle</i
                >
              </template>
              <google-sign-in-button
                v-else
                :redirect-url="connectionRedirectUrl"
                :text="$t('connect_with_google')"
                use-token
              />
            </div>

            <!-- facebook -->
            <div class="connection-item">
              <template v-if="socialConnections.facebook">
                <div class="connection-details fb-connection">
                  <img
                    class="connection-logo"
                    src="@/assets/f-logo58-white.png"
                  />
                  <div class="connection-info">
                    <span class="connection-title">{{
                      `${socialConnections.facebook.first_name} ${socialConnections.facebook.last_name}`
                    }}</span>
                    <span
                      class="connection-subline"
                      v-if="socialConnections.facebook.email"
                      >{{ socialConnections.facebook.email }}</span
                    >
                  </div>
                </div>
                <i
                  class="material-icons connection-control"
                  @click="removeConnection(socialConnections.facebook)"
                  >remove_circle</i
                >
              </template>
              <facebook-button
                v-else
                :redirect-url="connectionRedirectUrl"
                :text="$t('connect_with_facebook')"
                use-token
              ></facebook-button>
            </div>
          </div>
        </div>

        <account-terms class="setting-terms" />
      </div>
    </transition>

    <v-dialog
      v-model="dialog.show"
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="350"
      light
      class="mem-dialog"
    >
      <create-password-dialog
        v-if="dialog.show && dialog.type === 'create'"
        @hide="closePasswordDialog"
        @submit="submitPasswordDialog"
      />
      <change-password-dialog
        v-if="dialog.show && dialog.type === 'change'"
        @hide="closePasswordDialog"
        @submit="submitPasswordDialog"
      />
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { find, keyBy } from "lodash";
import parsePhoneNumber from "libphonenumber-js";
import { mapActions, mapGetters } from "vuex";
import { countries, states, nationalities } from "../lists";
import { numbersValidator, emailValidator } from "@/common/validators";
import { T_SHIRT_SIZES } from "@/common/const";
import geocoding from "@/common/mixins";

import SpartanPlus from "./SubscribePage/components/SpartanPlus.vue";
import UserSubscriptions from "./SettingsPage/components/UserSubscriptions.vue";
import CreatePasswordDialog from "@/views/SettingsPage/components/CreatePasswordDialog";
import ChangePasswordDialog from "@/views/SettingsPage/components/ChangePasswordDialog";
import GoogleSignInButton from "@/components/GoogleSignInButton";

export default {
  name: "settings",
  components: {
    ChangePasswordDialog,
    CreatePasswordDialog,
    SpartanPlus,
    UserSubscriptions,
    GoogleSignInButton,
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "mem-address-field": () =>
      import("@/components/base/BaseAddressSearch.vue"),
    "mem-phone-field": () => import("@/components/base/BasePhoneFieldV2.vue"),
    "facebook-button": () => import("@/components/ButtonFacebook.vue"),
    // "apple-auth-button": () => import("@/components/ButtonAppleAuth.vue"),
    "account-terms": () => import("./Auth/TermsSection.vue"),
  },
  mixins: [geocoding],
  data: () => ({
    // env: process.env.VUE_APP_ENV,
    states,
    countries,
    nationalities,
    profileForm: "",
    addressForm: "",
    accountForm: "",
    dateSelector: false,
    // gender: "M",
    formButtons: {
      showProfile: false,
      showAccount: false,
      showAddress: false,
    },
    screenWidth: 0,
    checkpoint: false,
    rules: {
      email: emailValidator,
      emailExist: true,
      phoneNumber: numbersValidator,
    },
    mobileView: {
      showMobileMenu: false,
      profile: true,
      addresses: true,
      subscription: true,
      account: true,
      connections: true,
    },
    autocomplete: null,
    componentForm: {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "short_name",
      postal_code: "short_name",
    },
    selectedAddress: {},
    dialog: {
      type: "",
      show: false,
      extra: null,
    },
    profileFormKey: 0,
    T_SHIRT_SIZES,
  }),
  computed: {
    ...mapGetters([
      "userData",
      "userSubscription",
      "userLanguage",
      "isLoadedStatus",
    ]),
    genders() {
      return [
        { full: this.$t("g_male"), short: "M" },
        { full: this.$t("g_female"), short: "F" },
      ];
    },
    statesList() {
      let { country_code } = this.userData || {};
      let country = find(this.states, ["name", country_code]);

      if (!country) return;

      return country[country_code];
    },
    showFormTitle() {
      let { profile, addresses, subscription, account, connections } =
        this.mobileView;

      if (profile && addresses && subscription && account && connections)
        return;

      return profile || addresses || subscription || account || connections;
    },
    transitionName() {
      if (this.mobileView.showMobileMenu) return "slide-x-reverse-transition";
      return "slide-x-transition";
    },
    formattedDate() {
      let { birth_date, spartan_country_code } = this.userData || {};
      if (!birth_date) return "";

      if (spartan_country_code === "US")
        return moment(this.userData.birth_date).format("MMMM DD, YYYY");

      return moment(this.userData.birth_date).format("DD MMMM, YYYY");
    },
    pendingEmail() {
      let { email_pending_reconfirmation } = this.userData || {};
      if (!email_pending_reconfirmation) return;

      return `${this.$t("pending")}: ${email_pending_reconfirmation}`;
    },
    socialConnections() {
      return keyBy(this.userData.oauth_authorizations, "provider");
    },
    connectionRedirectUrl() {
      return window.location.href;
    },
    password() {
      if (this.userData.empty_password) return "";
      return "******";
    },
    isSubscriptionAvailable() {
      const SUPPORTED_COUNTRIES = ["US"];
      let { spartan_country_code } = this.userData;

      return SUPPORTED_COUNTRIES.includes(spartan_country_code);
    },
    hasSubscriptionStatus() {
      return this.userSubscription && this.userSubscription.status;
    },
    useDevicePicker() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  methods: {
    ...mapActions([
      "patchUserData",
      "changePassword",
      "cancelProfileChanges",
      "cancelAddressChanges",
      "cancelAccountChanges",
      "loadAvatar",
      "deleteOAuthConnection",
      "handleToolbarNotification",
    ]),
    openMobileSection(section) {
      this.mobileView.showMobileMenu = false;
      this.mobileView[section] = true;
      // setTimeout(() => {
      //   if (section === "addresses") this.initGooglePlaces();
      // });
    },
    openMobileMenu() {
      this.mobileView.profile = false;
      this.mobileView.addresses = false;
      this.mobileView.subscription = false;
      this.mobileView.account = false;
      this.mobileView.connections = false;

      this.mobileView.showMobileMenu = true;
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 499 && !this.checkpoint) {
        this.checkpoint = true;
        this.mobileView = {
          showMobileMenu: true,
          profile: false,
          addresses: false,
          subscription: false,
          account: false,
          connections: false,
        };
      }
      if (this.screenWidth > 499) {
        this.checkpoint = false;
        this.mobileView = {
          showMobileMenu: false,
          profile: true,
          addresses: true,
          subscription: true,
          account: true,
          connections: true,
        };
      }
    },
    async saveUser() {
      this.$refs.profileForm.validate();
      if (
        !this.userData.first_name ||
        !this.userData.last_name ||
        !this.userData.birth_date
      )
        return;

      // Phone number fields check
      if (this.userData.mobile_phone) {
        let phone = parsePhoneNumber(`+${this.userData.mobile_phone}`);
        if (!phone?.isValid()) return;
      }
      if (this.userData.emergency_contact_phone) {
        let emergencyPhone = parsePhoneNumber(
          `+${this.userData.emergency_contact_phone}`
        );
        if (!emergencyPhone?.isValid()) return;
      }

      try {
        await this.patchUserData(this.userData);
        this.formButtons.showProfile = false;
        this.showUpdateNotification("profile");
      } catch (error) {
        console.log("Update profile error", error);
      }
    },
    async saveAddress() {
      await this.patchUserData(this.userData);
      this.formButtons.showAddress = false;
      this.showUpdateNotification("profile");
    },
    async changeEmail() {
      this.$refs.accountForm.validate();
      let emailValid = this.rules.email(this.userData.email);

      if (!emailValid) return;
      try {
        await this.patchUserData({ email: this.userData.email });
        this.rules.emailExist = true;
        this.formButtons.showAccount = false;
        this.showUpdateNotification("account");
      } catch (error) {
        console.log(error);
        if (error.type === "email")
          this.rules.emailExist = this.$t("email_exist");
      }
    },
    cancelProfile() {
      this.cancelProfileChanges();
      this.profileFormKey++;

      setTimeout(() => {
        this.formButtons.showProfile = false;
      }, 0);
    },
    cancelAccount() {
      this.cancelAccountChanges();
      this.rules.emailExist = true;
      this.formButtons.showAccount = false;
    },
    cancelAddress() {
      this.cancelAddressChanges();
      this.formButtons.showAddress = false;
    },
    async reqestChangePassword() {
      await this.changePassword(this.userData.email);
    },
    changeFn() {
      this.formButtons.showProfile = true;
    },
    changeAccountFn() {
      this.formButtons.showAccount = true;
    },
    changeAddressFn() {
      this.formButtons.showAddress = true;
    },
    showUpdateNotification(type) {
      // types: profile, account
      let notificationText;
      if (type === "profile") notificationText = "profile_updated";
      if (type === "account") notificationText = "check_inbox";

      this.handleToolbarNotification({
        type: "success",
        text: notificationText,
        isVisible: true,
        canClose: true,
        closeTimeout: 3000,
      });
    },
    async removeConnection(connectionData) {
      let { empty_password, oauth_authorizations } = this.userData;

      // create password & remove connection
      if (empty_password && oauth_authorizations?.length === 1) {
        this.dialog.extra = {
          connectionData,
        };
        return this.showPasswordDialog("create");
      }
      // remove connection
      await this.deleteConnectionRequest(connectionData);
    },
    async deleteConnectionRequest(connectionData) {
      try {
        await this.deleteOAuthConnection(connectionData.provider);
      } catch (error) {
        console.log(error);
        this.handleToolbarNotification({
          type: "warning",
          text: error,
          isVisible: true,
          canClose: true,
          closeTimeout: 3000,
        });
      }
    },
    showPasswordDialog(type) {
      // console.log(this.dialog, "DIALOG");
      this.dialog = {
        ...this.dialog,
        show: true,
        type,
      };
    },
    closePasswordDialog() {
      this.dialog = {
        show: false,
        type: "",
        extra: null,
      };
    },
    async submitPasswordDialog() {
      let { connectionData } = this.dialog.extra || {};
      this.closePasswordDialog();
      this.showUpdateNotification("profile");

      // remove social connection
      if (connectionData) await this.deleteConnectionRequest(connectionData);
    },

    // ---- geocoding ----

    fillAddressForm(address) {
      // address is empty
      if (!address) return;
      // address is string
      if (address && !address.formatted) return;
      // address is object
      let { state_code, city, postcode, country_code } = address;
      let ISO_COUNTRY_CODE = country_code || "";

      // userData

      // address1
      // this.userData.address1 = formatted;
      // address2
      // country_code
      this.userData.country_code = ISO_COUNTRY_CODE;
      // state
      if (ISO_COUNTRY_CODE === "AU" && state_code) {
        this.userData.state = `AU-${state_code}`;
      } else {
        this.userData.state = state_code || "";
      }
      // city
      this.userData.city = city || "";
      // zip
      this.userData.zip = postcode || "";

      this.formButtons.showAddress = true;
    },

    initGooglePlaces() {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { types: ["geocode"] }
      );
      this.autocomplete.setFields(["address_component"]);

      this.autocomplete.addListener("place_changed", this.fillInAddress);
      document.getElementsByName("g_autocomplete")[0].placeholder = "";
    },

    fillInAddress() {
      // Get the place details from the autocomplete object.
      var place = this.autocomplete.getPlace();
      this.selectedAddress = {};
      console.log(place, "place");
      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (this.componentForm[addressType]) {
          var val =
            place.address_components[i][this.componentForm[addressType]];
          this.selectedAddress[addressType] = val;
        }
      }
      this.fillAddressInputs();
    },
    fillAddressInputs() {
      // address1 input
      if (this.selectedAddress.street_number && this.selectedAddress.route) {
        this.userData.address1 = document.getElementById("autocomplete").value =
          this.selectedAddress.street_number + " " + this.selectedAddress.route;
      } else {
        this.userData.address1 = document.getElementById("autocomplete").value;
      }

      // country
      if (this.selectedAddress.country) {
        this.userData.country_code = this.selectedAddress.country;
      } else this.userData.country = "";
      // state
      if (this.selectedAddress.administrative_area_level_1) {
        if (this.selectedAddress.country === "AU")
          this.userData.state =
            "AU-" + this.selectedAddress.administrative_area_level_1;
        else
          this.userData.state =
            this.selectedAddress.administrative_area_level_1;
      } else this.userData.state = "";
      // city
      if (this.selectedAddress.locality) {
        this.userData.city = this.selectedAddress.locality;
      } else this.userData.city = "";
      // zip
      if (this.selectedAddress.postal_code)
        this.userData.zip = this.selectedAddress.postal_code;
      else this.userData.zip = "";

      this.formButtons.showAddress = true;
    },
  },
  watch: {
    "userData.mobile_phone": {
      handler(newValue, oldValue) {
        if (!oldValue) return;
        this.changeFn();
      },
    },
    "userData.emergency_contact_phone": {
      handler(newValue, oldValue) {
        if (!oldValue) return;
        this.changeFn();
      },
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadAvatar();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.profile-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 722px;
  margin: 0 auto;
  @media screen and (min-width: $mobile) and (max-width: 770px) {
    max-width: 100%;
    padding: 0 24px;
  }
  @media screen and (max-width: $mobile) {
    padding: 0;
    margin: 0;
  }

  .settings-header {
    width: 100%;
    margin-top: 70px;
    @include elementMarginBottom("M");

    @media screen and (max-width: $mobile) {
    }
  }

  .mobile-header {
    display: none;

    position: relative;
    width: 100%;
    padding-top: 70px;
    @include elementMarginBottom("M");
    @media screen and (max-width: $mobile) {
      display: block;
    }
    .button-back {
      position: absolute;
      top: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 14px;
      padding: 0 3px;
      // margin-bottom: 17px;
      @include cursorPointer;
      i {
        font-size: 34px;
      }
    }
    .section-title {
      padding: 0 16px;
    }
  }

  .mobile-menu {
    width: 100%;
    display: none;
    @media screen and (max-width: $mobile) {
      display: block;
    }

    .menu-item {
      height: 72px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #121212;
      margin-bottom: 4px;
      padding: 0 16px 0 24px;
      @include cursorPointer;
      i {
        font-size: 32px;
      }
    }
  }

  .settings-forms {
    width: 100%;
    .settings-card {
      background: #121212;
      display: flex;
      flex-direction: column;
      align-items: center;

      border-radius: 5px;
      padding: 36px 32px 32px;
      margin-bottom: 4px;
      @media screen and (max-width: $mobile) {
        border: none;
        border-radius: 0;
        height: 100%;
        padding: 50px 24px 0 24px;
      }
      .card-header {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        width: 100%;
        margin-bottom: 50px;
        @media screen and (max-width: $mobile) {
          display: none;
        }
      }
      .card-body {
        max-width: 413px;
        width: 100%;
        margin-bottom: 18px;
        ::v-deep(.v-label) {
          font-size: 14px;
        }
        .settings-field {
          ::v-deep(.field-label) {
            font-size: 14px;
            text-transform: uppercase;
          }
        }
        .card-buttons {
          margin-top: 6px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 6px;
        }
      }
    }
    .setting-terms {
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;

      padding-top: 32px;
      padding-bottom: 32px;
      @media screen and (max-width: $mobile) {
        flex-direction: column;
      }
    }
  }
}

input[type="password"] {
  font-size: 18px;
  letter-spacing: 0.185em;
}

.mobile-picker {
  ::v-deep {
    .v-text-field__slot {
      z-index: 1;

      input {
        text-align: left;
        &::-webkit-date-and-time-value {
          text-align: left;
        }
      }
    }

    .v-input__append-inner {
      position: absolute !important;
      right: 0;
    }
  }
}

.password-input {
  ::v-deep .v-input__slot {
    // min-height: 48px;
    .input-control {
      color: #aaaaaa;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: white;
      }
    }
  }
}

.connections-block {
  .connection-item {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;

    .connection-details {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 37px;
      height: 50px;
      // padding: 10px 15px;

      .connection-logo {
        position: absolute;
        width: 20px;
        height: 20px;
        // top: calc(50% - 10px);
        left: 15px;
      }

      .connection-info {
        display: flex;
        flex-direction: column;
        flex: 1 0;
        max-width: 280px;
        @media screen and (max-width: 499px) {
          max-width: 200px;
        }
        .connection-title {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 150%;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .connection-subline {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #ababab;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      & + .connection-control {
        margin-left: 20px;
      }
    }
    .apple-connection {
      background-color: #272727;
      .connection-logo {
        filter: invert(1);
      }
    }
    .google-connection {
      background-color: #272727;
    }
    .fb-connection {
      background-color: #407ada;
      .connection-info {
        .connection-subline {
          color: #d1d1d1;
        }
      }
    }
    .connection-control {
      font-size: 20px;
      color: white;
      cursor: pointer;
    }
    & + .connection-item {
      margin-top: 16px;
    }
  }

  @media screen and (max-width: 499px) {
    padding-bottom: 50px !important;

    .card-body {
      margin-bottom: 0;
    }
  }
}
</style>
