<template>
  <button
    :class="[btnType, loading ? 'is-loading' : '']"
    type="button"
    v-bind="$attrs"
    @click="buttonClick"
  >
    <!-- Adding opacity to slot to save button width -->
    <template v-if="keepSlot">
      <div class="slot-wrapper">
        <div class="button-slot" :class="{ 'opacity-0': loading }">
          <slot></slot>
        </div>
        <v-progress-circular
          v-if="loading"
          class="progress-loader"
          indeterminate
          :color="currentColor"
          :size="loaderSize"
          :width="2"
        />
      </div>
    </template>
    <template v-else>
      <slot v-if="!loading"></slot>
      <v-progress-circular
        v-else
        indeterminate
        :color="currentColor"
        :size="loaderSize"
        :width="2"
      />
    </template>
  </button>
</template>
<script>
export default {
  name: "BaseButton",
  props: {
    btnType: {
      type: String,
      default: "mem-primary",
      validator: (val) =>
        [
          "mem-primary",
          "secondary-dark",
          "secondary-light",
          "tertiary-dark",
          "tertiary-light",
        ].includes(val),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    keepSlot: Boolean,
    loaderSize: {
      type: Number,
      default: 32,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentColor() {
      if (this.light) return "black";
      if (this.dark) return "white";
      return this.btnType.toString().includes("dark") ? "white" : "black";
    },
  },
  methods: {
    buttonClick(e) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 200);
      this.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.base-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.opacity-0 {
  opacity: 0;
}
button {
  height: 44px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 22.7053px;
  padding: 0 24px;
  letter-spacing: 0.04em;
  font-size: 14px;
  transition: box-shadow 0.18s ease-in-out;
  .slot-wrapper {
    position: relative;
    .progress-loader {
      position: absolute;
      top: 2.5px;
      left: 0;
      right: 0;
      margin-inline: auto;
      z-index: $zindex-page-element;
    }
  }
}
button:disabled {
  pointer-events: none;
}
button:not(.is-disabled):focus {
  box-shadow: 0px 0px 20px #2596ff;
  outline: none;
}
.mem-primary {
  color: #ffffff;
  background: #cf1019;
  opacity: 1;
  transition: opacity 0.2s ease-in;

  &:disabled {
    background-color: #e0e0e0;
    color: #aaaaaa;
  }
}
.mem-primary:hover {
  opacity: 0.7;
}

.secondary-dark {
  color: #191818 !important;
  background: #ffffff;
}
.secondary-dark:disabled {
  background-color: transparent;
  border: 1px solid #999999;
  color: #999999 !important;
}
.secondary-dark:hover:not(:disabled) {
  color: #ffffff !important;
  background: #cf1019;
  // transition: 150ms linear;
}

.secondary-light {
  color: #ffffff;
  background: #121212;
}
.secondary-light:hover:not(:disabled) {
  color: #ffffff;
  background: #cf1019;
}
.secondary-light:disabled {
  background: #d0d0d0;
}

.tertiary-dark {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.tertiary-dark:hover {
  color: #191818;
  background: #ffffff;
  border: 1px solid #ffffff;
}
.tertiary-dark:disabled {
  border: 1px solid rgba(255, 255, 255, 0.21);
  background: inherit;
  color: rgba(255, 255, 255, 0.2);
  cursor: default !important;
}

.tertiary-light {
  color: #121212;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.tertiary-light:hover {
  color: #ffffff;
  background: #121212;
  border: 1px solid #121212;
}
.tertiary-light:disabled {
  // border: 1px solid rgba(255, 255, 255, 0.21);
  // background: inherit;
  // color: rgba(255, 255, 255, 0.2);
  // cursor: default !important;
}
.is-loading {
  pointer-events: none;
  .slot-wrapper {
    height: 100%;
  }
}
.is-loading,
.is-loading:hover,
.is-loading:focus {
  display: block;
  background: transparent !important;
  cursor: default;
  box-shadow: none !important;
}
</style>
